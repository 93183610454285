import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import pic1 from './pic4.png';
import pic2 from './pic5.png';
import pic3 from './pic6.png';
import css from './SectionPresentation.module.css';

const SectionPresentation = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionPresentation.titleLineOne" />
        <br />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <img src={pic1} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionPresentation.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionPresentation.part1Text" />
          </p>
        </div>

        <div className={css.step}>
        <img src={pic2} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionPresentation.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionPresentation.part2Text" />
          </p>
        </div>

        <div className={css.step}>
        <img src={pic3} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionPresentation.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionPresentation.part3Text" />
          </p>
        </div>
      </div>

      {/* <div className={css.createListingLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionPresentation.createListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

SectionPresentation.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionPresentation.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPresentation;
