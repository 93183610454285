import React, {useState} from 'react'
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import Button from '../../components/Button/Button';
import axios from 'axios';
import { CssBaseline } from '@material-ui/core';
import css from './PayoutExtraVerifyInfo.module.css';


const PayoutExtraVerifyInfo = (props) => {

    const frontIdRef = React.createRef();
    const backIdRef = React.createRef();
    const frontAdress = React.createRef();
    const backAdress = React.createRef();

    const handlePhoneNumber = (e) => {
        if(e.target.value.length < 11){
        props.setPhoneNumber(e.target.value)
        }
    }

    const handleEmail = (e) => {
        props.setEmail(e.target.value)
    }

    const handleFrontIdClick = () => {
        /*Collecting node-element and performing click*/
        frontIdRef.current.click();
      }

      const handleBackIdClick = () => {
        /*Collecting node-element and performing click*/
        backIdRef.current.click();
      }

      const handleFrontAdress = () => {
        /*Collecting node-element and performing click*/
        frontAdress.current.click();
      }

      const handleBackAdress = () => {
        /*Collecting node-element and performing click*/
        backAdress.current.click();
      }


    return (
        <div>
            <p className={css.fieldLabel}>E-Mail-Adresse</p>
            <input value={props.email} placeholder="exemple@exemple.com" onChange={handleEmail}/>
            
            <p className={css.fieldLabel}>Telefonnummer</p>
            <div className={css.phoneNumber}>
            +49<input type="number" placeholder="123456789" value={props.phoneNumber} onChange={handlePhoneNumber}/>
            </div>
        <br/>
        <p className={css.warningText}>*Bitte laden Sie Bilder im JPG- oder PNG-Format und mit einer Größe von weniger als 12 MB hoch.</p>

            <p className={css.fieldLabel}>Vorderseite des Personalausweises</p>
            <input 
            type="file" 
            id={`${props.fieldId}.frontId`} 
            onChange={props.onFileChangeFrontID} 
            accept=".jpeg,.jpg,.png"
            ref={frontIdRef}
            className={css.hiddenInput}
            />
            <div onClick={handleFrontIdClick} className={props.frontID ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{props.frontID ? props.frontID.name : "keine Datei ausgewählt"}</span>
            </div>


            <p className={css.fieldLabel}>Rückseite des Personalausweises</p>
            <input 
            type="file" 
            id={`${props.fieldId}.backId`} 
            onChange={props.onFileChangeBackID} 
            accept=".jpeg,.jpg,.png"
            ref={backIdRef}
            className={css.hiddenInput}
            />

            <div onClick={handleBackIdClick} className={props.backID ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{props.backID ? props.backID.name : "keine Datei ausgewählt"}</span>
            </div>

            <br/>

            <p className={css.fieldLabel}>Vorderseite des Adressnachweises</p>
            <input 
            type="file" 
            id={`${props.fieldId}.adressProofFront`} 
            onChange={props.onFileChangeAdressProofFront}
            ref={frontAdress}
            className={css.hiddenInput}
            />

            <div onClick={handleFrontAdress} className={props.frontAdress ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{props.frontAdress ? props.frontAdress.name : "keine Datei ausgewählt"}</span>
            </div>

            <p className={css.fieldLabel}>Rückseite des Adressnachweises</p>
            <input 
            type="file" 
            id={`${props.fieldId}.adressProofBack`} 
            onChange={props.onFileChangeAdressProofBack}
            ref={backAdress}
            className={css.hiddenInput}
            />

            <div onClick={handleBackAdress} className={props.backAdress ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{props.backAdress ? props.backAdress.name : "keine Datei ausgewählt"}</span>
            </div>
            <br/>
        </div>
    )
}

export default PayoutExtraVerifyInfo
