import React, { useState, useLayoutEffect } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';
import { parse, stringify } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import Geocoder, { GeocoderAttribution, CURRENT_LOCATION_ID } from '../LocationAutocompleteInput/GeocoderMapbox';
import IconHourGlass from '../LocationAutocompleteInput/IconHourGlass';
import css from './SectionHero.module.css';



function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const SectionHero = props => {
  const { rootClassName, className, mobileSearchInputsComponent } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const [width, height] = useWindowSize();

    const toogleModal = () => {
      setModalOpen(true);
    }

    const locationSearch = typeof window !== 'undefined' ? location.search : '';
  const { address, origin, bounds } = parse(locationSearch, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const locationFieldsPresent = config.sortSearchByDistance
  ? address && origin && bounds
  : address && bounds;
const initialSearchFormValues = {
  location: locationFieldsPresent
    ? {
        search: address,
        selectedPlace: { address, origin, bounds },
      }
    : null,
};

     const onSearchSubmit = (values) => {
        const { currentSearchParams } = props;
        const { search, selectedPlace } = values.location;
        const { history } = props;
        const { origin, bounds } = selectedPlace;
        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        const searchParams = {
          ...currentSearchParams,
          ...originMaybe,
          address: search,
          bounds,
        };
        history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
      }

  return (
    <div className={classes}>
      <div className={css.heroContent}>

     { width > 800 ?
        <>



        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
          <p className={css.text1}>
            <center>
              <p className={css.bigText1}>Der #1 Marktplatz für Miet-</p>
              <p className={css.bigText2}>Garagen</p>
              <p className={css.smallText}>Entdecke Garagen in Deiner Umgebung</p>
            </center>
          </p>
        <TopbarSearchForm
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={onSearchSubmit}
          initialValues={initialSearchFormValues}
        />
  
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=Germany&bounds=55.1145032951348%2C15.0418329997963%2C47.2702370080945%2C5.86631401478655',
          }}
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}

        
        </> : 
        <>
                <div className={css.dummySearchImput} onClick={toogleModal}>
              <IconHourGlass/>    
                 <span className={css.dummyPlaceholder}>Wo suchst du?</span>
              </div>

          <p className={css.text1M}>
            <center>
              <p className={css.bigText1M}>Der #1 Marktplatz für Miet-Garagen</p>
              <p className={css.smallTextM}>Entdecke Garagen in Deiner Umgebung</p>
            </center>
          </p>

        </>
      }

          {modalOpen ? 
                <>
                    <div className={css.blurryBackground} onClick={() => setModalOpen(false)}>

                      </div>
                      <div className={css.mobileHeroSection}>
                            <span className={css.closeButton} onClick={() => setModalOpen(false)}>CLOSE X</span>
                              {mobileSearchInputsComponent}
                        </div>
                        
                    </>    
                    : null}

      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
