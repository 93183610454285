import React, { useState, useEffect} from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import { FormattedMessage } from '../../util/reactIntl';
import css from './MonthsPicker.module.css';

function MonthsPicker(props) {
    const [year, setYear] = useState(2022);
    const {blockedMonths, setBlockedMonths} = props;
    const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul","Aug", "Sep", "Okt", "Nov", "Dez"]; 
    const d = new Date();
    const currentMonth = d.getMonth();
    const currentYear = d.getFullYear();

    const handleMonthClick = (m) => {
        const eventObj = {month: m, year: year };

        const index = blockedMonths.findIndex(function(eventObj, i){
            return eventObj.month === m && eventObj.year === year
          });

        if (index > -1) {
            const blockedMonthsCopy = [...blockedMonths];
            blockedMonthsCopy.splice(index, 1);
            setBlockedMonths(blockedMonthsCopy);

        }else{
            const newMonthBlockedDate = [...blockedMonths, eventObj];
            setBlockedMonths(newMonthBlockedDate);
        }

       
    }
   
    const handleYear = (action) => {
            if(action === 'increase' && year < 2028){
                setYear(year + 1)
            }else if(action === 'decrease' && year > 2022){
                setYear(year - 1)
            }
    }
    return (
        <div className={css.wrapper}>
                <div className={css.yearWrappper}>
                    <ArrowBackIosIcon className={css.arrow} onClick={() => handleYear('decrease')}/>
                    <span className={css.yearLabel}>{year}</span>
                    <ArrowForwardIosIcon className={css.arrow} onClick={() => handleYear('increase')}/>
                </div>

                <div className={css.monthsWrapper}>
                        <Grid container xs={12}>
                                {months.map(m => {
                                     const eventObj = {month: m, year: year };

                                    //  const index = blockedMonths.findIndex(function(eventObj, i){
                                    //     return eventObj.month === m && eventObj.year === year
                                    //   });

                                    const isPassedYear = year < currentYear;
                                    const isSameYear = year === currentYear;
                                    const selectedMonthIndex = months.indexOf(m);
                                    const isPassedMonth = currentMonth > selectedMonthIndex;
                                    const isPassedMonthF = isSameYear && isPassedMonth;

                                      const found = blockedMonths.find(el => {
                                          return el.month === m && el.year === year
                                      })

                                    const isBlocked = found || isPassedYear  || isPassedMonthF;
                                 
                                    return(
                                        <Grid container item xs={4} justifyContent='center'>
                                            <Button 
                                            className={isBlocked ? css.monthButtonBlocked : css.monthButton} 
                                            variant="contained"
                                            onClick={() => handleMonthClick(m)}
                                            >{m}</Button>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                </div>
                <div className={css.legend}>
          <div className={css.legendRow}>
            <span className={css.legendAvailableColor} />
            <span className={css.legendText}>
            <FormattedMessage id="EditListingAvailabilityForm.availableLabel" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendBlockedColor} />
            <span className={css.legendText}>
            <FormattedMessage id="EditListingAvailabilityForm.blockedLabel" />
            </span>
          </div>
          
        </div>
        </div>
    )
}

export default MonthsPicker
