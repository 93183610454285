import React from 'react'
import css from './SubscriptionBreakdown.module.css';
import {  FormattedMessage } from '../../util/reactIntl';

const LineItem = (props) => {
    const {label, value} = props;

    return(
        <div className={css.lineItem}>
        <p>{label}</p>
        <p className={css.limitedLengthText}>{value}</p>
    </div>
    )
}

Date.prototype.toGerman = function() {
    var date = new Date(this.valueOf());
    const year = date.getFullYear();
    const month = date.getMonth();
    const dateN = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
  
    const germanMonths = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  
    const dateString = dateN + ' ' + germanMonths[month] + ' ' + year;
    return dateString;
  }

function SubscriptionBreakdown(props) {

    const { hideBreakdown, currentListing, priceTitle, date, customerCommissionLabel } = props;
    const publicData = currentListing?.attributes.publicData;
    const location = publicData?.location;
    const minRentingMonths = publicData?.minRentingMonths;
    const address = location?.address;
    const author = currentListing?.author.attributes.profile.displayName;
    const formatedDate = date ? new Date(date).toGerman() : '';
  
    const lineItems = [
    {
        label: <FormattedMessage id="Breakdown.location"/>,
        value: address
    },
    {
        label: <FormattedMessage id="Breakdown.owner"/>,
        value: author
    },
    {
        label: <FormattedMessage id="Breakdown.rentPrice"/>,
        value: priceTitle
    },
    {
        label: <FormattedMessage id="Breakdown.rentStart"/>,
        value: formatedDate
    },
    {
        label: <FormattedMessage id="Breakdown.rentDuration"/>,
        value: <FormattedMessage id="Breakdown.minNrOfMonths" values={{minRentingMonths}}/>
    },
    {
        label: <FormattedMessage id="Breakdown.commission"/>,
        value: customerCommissionLabel
    }
    ]
    
    return (
        <div 
        className={hideBreakdown ? css.breakdownWrapperHidden : css.breakdownWrapper}
        >
            <p className={css.field}><FormattedMessage id="Breakdown.greeting"/></p>

            {lineItems.map(lineItem => {
                return(
                    <LineItem label={lineItem.label} value={lineItem.value} />
                )
            })}
            
        </div>
    )
}

export default SubscriptionBreakdown
