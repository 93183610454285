import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';
import dynamic from 'next/dynamic';
import berlin from './images/berlin.jpg';
import hamburg from './images/hamburg.jpg';
import munich from './images/munich.jpg';
import './style.css';
import { slidesToShowPlugin } from '@brainhubeu/react-carousel';




class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const Carousel = typeof window !== 'undefined' ? require('@brainhubeu/react-carousel').default : dynamic(() => import('@brainhubeu/react-carousel'), {
    ssr: false,
  });

  return (
    <div className={classes}>
      <div className={css.title}>
        <center><FormattedMessage id="SectionLocations.title" /></center>
      </div>
      <div className={css.locations}>
      <Carousel 
      plugins={[
      
        'arrows',
        {
          resolve: slidesToShowPlugin,
          options: {
           numberOfSlides: 3
          }
        },
      ]}

      breakpoints={{
        780: {
          plugins: [
           {
             resolve: slidesToShowPlugin,
             options: {
              numberOfSlides: 1
             }
           },
         ]
        },
      }}
      >
        {locationLink(
          'Berlin',
          berlin,
          '?address=Berlin%2C%20Germany&bounds=52.6755029827484%2C13.761131997363%2C52.3382670008426%2C13.0883590415111'
        )}
        {locationLink(
          'Hamborg',
          hamburg,
          '?address=Hamburg%2C%20Germany&bounds=54.0187463964527%2C10.3253449681247%2C53.3949960146367%2C8.30956670062143'
        )}
        {locationLink(
          'München',
          munich,
          '?address=Munich%2C%20Bavaria%2C%20Germany&bounds=48.248216%2C11.722879%2C48.061596%2C11.360781'
        )}

        {locationLink(
          'Berlin',
          berlin,
          '?address=Berlin%2C%20Germany&bounds=52.6755029827484%2C13.761131997363%2C52.3382670008426%2C13.0883590415111'
        )}
        {locationLink(
          'Hamborg',
          hamburg,
          '?address=Hamburg%2C%20Germany&bounds=54.0187463964527%2C10.3253449681247%2C53.3949960146367%2C8.30956670062143'
        )}
        {locationLink(
          'München',
          munich,
          '?address=Munich%2C%20Bavaria%2C%20Germany&bounds=48.248216%2C11.722879%2C48.061596%2C11.360781'
        )}
          </Carousel>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
