/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_features'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Termine',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  {
    id: 'price',
    label: 'Preis',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'instantBook',
    label: 'Buchungsart',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_bookingType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'instant', label: 'Sofort buchbar' },
        { key: 'request', label: 'Buchungsanfrage' },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Stichwort',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Kategorie',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'outside', label: 'Außen' },
        { key: 'underground', label: 'Unterirdisch' },
        
      ],
    },
  },
  {
    id: 'features',
    label: 'Merkmale',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_features'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'heating',
          label: 'Heizung',
        },
        {
          key: 'powerPlug',
          label: 'Netzstecker',
        },
        {
          key: 'electronicLock',
          label: 'Elektronisches Schloss',
        },
     
      ],
    },
  },
  {
    id: 'garagentor',
    label: 'Garagentor',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_garagentor'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'elektrisch',
          label: 'Elektrisch',
        },
        {
          key: 'manuell',
          label: 'Händisch',
        },

      ],
    },
  },

  {
    id: 'stromanschluss',
    label: 'Netzanschluss',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_stromanschluss'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'yes',
          label: 'Ja',
        },
        {
          key: 'no',
          label: 'Nein',
        },

      ],
    },
  },

  {
    id: 'artDerGarage',
    label: 'Eine Art Garage',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_artDerGarage'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'tiefgarage',
          label: 'Unterirdisch',
        },
        {
          key: 'stellplatz',
          label: 'Parkplatz',
        },
        {
          key: 'garage',
          label: 'Garage',
        },
        {
          key: 'duplex',
          label: 'Duplex',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Neueste' },
    { key: '-createdAt', label: 'Älteste' },
    { key: '-price', label: 'Geringster Preis' },
    { key: 'price', label: 'Höchster Preis' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevanz', longLabel: 'Relevanz (Stichwortsuche)' },
  ],
};
