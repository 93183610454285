import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import Button from '../../components/Button/Button';
import classNames from 'classnames';
import './lib/styles.css';
import { NamedLink } from '../../components';
import Carousel from 'react-multi-carousel';
import css from './SectionFavListings.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');
const { UUID } = sdkTypes;

const sdkUtil = sharetribeSdk.util;
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
});




const SectionFavListings = props => {
  const { rootClassName, className, history } = props;
  const [listingsJsonsArray, setListingsJsonsArray] = useState([]);


  useEffect(() => {


   const listingsIdsArray = [
    //  "61602461-32ee-441c-ba04-c79a1af090aa",
    //  "614336cc-3d31-4f4b-b1fc-d79028c3a7c5",
    //  "6138681f-aef5-4b45-8e6a-bb387b5d2606"
   ];  // add id's of listing here like this "60b5d66c-5d04-4dd6-9be2-06d8d154016d"

   const promisesArray = listingsIdsArray.map(listingId => {
    return sdk.listings.show({id: listingId,
       include: ['author', 'images'],
       "fields.image": ["variants.square-small", "variants.my-variant"],
       "imageVariant.my-variant": sdkUtil.objectQueryString({
         w: 320,
         h: 640,
         fit: 'scale'
       }),
   
     }).then(resp => {
       const listingPicture = resp.data.included[1].attributes.variants["my-variant"].url;
       const listingPrice = resp.data.data.attributes.price.amount / 100;
       const listingTitle = resp.data.data.attributes.title;
       const listingHost = resp.data.included[0].attributes.profile.displayName;
       
     return {
        listingId: listingId,
         pictureLink: listingPicture,
         listingPrice: listingPrice,
         listingTitle: listingTitle,
         listingHost: listingHost
       } 
      
     }).catch(error => console.log(error.stack))
 });

 
       Promise.all(promisesArray).then(resp => {
          // console.log("3#")
          // console.log("array with all the closed dates that we just fetched from external source")
          // console.log(resp.flat())
          const flattenJustFetchedDatesArray = resp.flat();
          return setListingsJsonsArray(flattenJustFetchedDatesArray)
        }).catch(error => {
          console.log(error.stack)
        })



  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 864, min: 0 },
      items: 1
    }
  };



  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
      <span className={css.leftTitle}>Top-Angebote für Sie</span> 
      </div>

      <Carousel
       responsive={responsive}
       swipeable={true}
       draggable={true}
       arrows={true}
       showDots={false}
       ssr={false} // means to render carousel on server-side.
       keyBoardControl={false}
       >
        {
          listingsJsonsArray.map(listing => {
            return(
              <div className={css.listingCard} onClick={() => {
                if(typeof window !== 'undefined'){
                  window.location.replace(`${window.location.origin}/l/${listing.listingTitle}/${listing.listingId}`)
                 }
              
                }}>
                  <img src={listing.pictureLink} className={css.listingCardImage}/>
                  <div className={css.listingInfo}>
                      <div className={css.listingInfoPrice}>
                            <span><span className={css.priceNumber}>{listing.listingPrice}</span><span className={css.priceCurrency}>€</span></span>
                            <span>pro Monat</span>
                      </div>
                      <div className={css.listingInfoDetails}>
                          <span className={css.listingTitle}>{listing.listingTitle}</span>
                          <span className={css.listingHost}>{listing.listingHost}</span>
                          
                      </div>
                  </div>
              </div>
            )
          })
        }
     </Carousel>
      
        <Button
         className={css.parkingSpotsButton}
         onClick={() => {
           if(typeof window !== 'undefined'){
            window.location.replace(`${window.location.origin}/s`)
           }
         }}
         >alle Angebote anzeigen</Button>
    </div>
  );
};

SectionFavListings.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFavListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFavListings;
