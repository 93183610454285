import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-berlin',
    predictionPlace: {
      address: 'Germany, Berlin',
      bounds: new LatLngBounds(new LatLng(52.6755029827484, 13.761131997363), new LatLng(52.3382670008426, 13.0883590415111)),
    },
  },
 
];
export default defaultLocations;
