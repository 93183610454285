import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import pic1 from './pic1.png';
import pic2 from './pic2.png';
import pic3 from './pic3.png';
import css from './SectionAdvantages.module.css';

const SectionAdvantages = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionAdvantages.titleLineOne" />
        <br />
      </div>

      <div className={css.steps}>
        <div className={css.step}>
          <img src={pic1} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionAdvantages.part1Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionAdvantages.part1Text" />
          </p>
        </div>

        <div className={css.step}>
        <img src={pic2} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionAdvantages.part2Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionAdvantages.part2Text" />
          </p>
        </div>

        <div className={css.step}>
        <img src={pic3} className={css.picStyle}/>
          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionAdvantages.part3Title" />
          </h2>
          <p>
            <FormattedMessage id="SectionAdvantages.part3Text" />
          </p>
        </div>
      </div>

      {/* <div className={css.createListingLink}>
        <NamedLink name="NewListingPage">
          <FormattedMessage id="SectionAdvantages.createListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

SectionAdvantages.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAdvantages.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAdvantages;
