import React from 'react';
import { bool, object, shape } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';

import PayoutDetailsAddress from './PayoutDetailsAddress';
import PayoutDetailsCompany from './PayoutDetailsCompany';
import PayoutDetailsBankDetails from './PayoutDetailsBankDetails';
import PayoutDetailsAccountOpener from './PayoutDetailsAccountOpener';
import PayoutDetailsAdditionalPersons from './PayoutDetailsAdditionalPersons';
import { stripeCountryConfigs } from './PayoutDetailsForm';
import css from './PayoutExtraVerifyInfo.module.css';

const CompanyAccountComponent = props => {
  const { 
    fieldRenderProps, 
    intl,
    phoneNumber, 
    setPhoneNumber, 
    setCompanyFileFront, 
    setCompanyFileBack,
    setCompanyPersonFileFront,
    setCompanyPersonFileBack,
    setCompanyPersonAdditionalFileFront,
    setCompanyPersonAdditionalFileBack,
    companyFileFront,
    companyFileBack,
    companyPersonFileFront,
    companyPersonFileBack,
    companyPersonAdditionalFileFront,
    companyPersonAdditionalFileBack,
  } = props;

  const companyFileFrontRef = React.createRef();
  const companyFileBackRef = React.createRef();
 
  // const companyPersonAdditionalFileFrontRef = React.createRef();
  // const companyPersonAdditionalFileBackRef = React.createRef();

  const handleCompanyFileFrontRef  = () => {
    companyFileFrontRef.current.click();
  }

  const handleCompanyFileBackRef  = () => {
    companyFileBackRef.current.click();
  }



  // const handleCompanyPersonAdditionalFileFrontRef  = () => {
  //   companyPersonAdditionalFileFrontRef.current.click();
  // }

  // const handleCompanyPersonAdditionalFileBackRef  = () => {
  //   companyPersonAdditionalFileBackRef.current.click();
  // }

  const { disabled, form, values } = fieldRenderProps;
  const { country } = values;
  const { push } = form && form.mutators ? form.mutators : {};

  const companyConfig =
    country && stripeCountryConfigs(country).companyConfig
      ? stripeCountryConfigs(country).companyConfig
      : {};

  const showBusinessURLField = !!companyConfig.businessURL;
  const showCompanyPhoneNumberField = !!companyConfig.companyPhone;
  const showMCCForUSField = !!companyConfig.mccForUS;
  const showPersonalEmailField = !!companyConfig.personalEmail;
  const showPersonalAddressField = !!companyConfig.personalAddress;
  const showPersonalIdNumberField =
    !!companyConfig.personalIdNumberRequired || !!companyConfig.ssnLast4Required;
  const showPersonalPhoneNumberField = !!companyConfig.personalPhone;
  const showOwnerFields = !!companyConfig.owners;

  const handlePhoneNumber = (e) => {
    if(e.target.value.length < 10){
      setPhoneNumber(e.target.value)
    }
  }

  const onFileChangeCompanyFront = (event) => {
    setCompanyFileFront(event.target.files[0]);
 }

 const onFileChangeCompanyBack = (event) => {
  setCompanyFileBack(event.target.files[0]);
}

  return (
    <React.Fragment>
      {country ? (
        <React.Fragment>
          <PayoutDetailsCompany
            country={country}
            disabled={disabled}
            fieldId="company"
            intl={intl}
            showPhoneNumberField={showCompanyPhoneNumberField}
            showMCCForUSField={showMCCForUSField}
            showBusinessURLField={showBusinessURLField}
          />

          <PayoutDetailsAddress
            country={country}
            intl={intl}
            disabled={disabled}
            form={form}
            fieldId="company.address"
            companyBuildingAdress={true}
          />

            <p className={css.fieldLabel}>Telefonnummer</p>
            <div className={css.companyPhone}>
            +49<input type="number" placeholder="123456789" value={phoneNumber} onChange={handlePhoneNumber}/>
            </div>
            <br/>

          <PayoutDetailsBankDetails country={country} disabled={disabled} fieldId="company" />
          <p className={css.warningText}>*Bitte laden Sie Bilder im JPG- oder PNG-Format und mit einer Größe von weniger als 12 MB hoch.</p>

          <p className={css.fieldLabel}>Vorderseite des Unternehmensbestätigungsdokuments</p>
            <input 
            type="file" 
            id={`${props.fieldId}.companyFrontDoc`} 
            onChange={onFileChangeCompanyFront}
            ref={companyFileFrontRef}
            className={css.hiddenInput}
            />

          <div onClick={handleCompanyFileFrontRef} className={companyFileFront ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{companyFileFront ? companyFileFront.name : "keine Datei ausgewählt"}</span>
            </div>
            

            <p className={css.fieldLabel}>Rückseite des Unternehmensbestätigungsdokuments</p>
            <input 
            type="file" 
            id={`${props.fieldId}.companyBackDoc`} 
            onChange={onFileChangeCompanyBack}
            ref={companyFileBackRef}
            className={css.hiddenInput}
            />
            <div onClick={handleCompanyFileBackRef} className={companyFileBack ? css.uploadAreaSelected : css.uploadAreaNotSelected}>
                <span>{companyFileBack ? companyFileBack.name : "keine Datei ausgewählt"}</span>
            </div>
            <br/>

          <PayoutDetailsAccountOpener
            country={country}
            disabled={disabled}
            fieldId="accountOpener"
            form={form}
            intl={intl}
            showEmailField={showPersonalEmailField}
            showOrganizationTitleField
            showOwnerField={showOwnerFields}
            showPersonalAddressField={showPersonalAddressField}
            showPersonalIdNumberField={showPersonalIdNumberField}
            showPhoneNumberField={showPersonalPhoneNumberField}
            values={values}
            companyPersonAdditionalFileFront={companyPersonAdditionalFileFront}
            companyPersonAdditionalFileBack={companyPersonAdditionalFileBack}
            companyPersonFileFront={companyPersonFileFront}
            companyPersonFileBack={companyPersonFileBack}
            setCompanyPersonFileFront={setCompanyPersonFileFront}
            setCompanyPersonFileBack={setCompanyPersonFileBack}
            setCompanyPersonAdditionalFileFront={setCompanyPersonAdditionalFileFront}
            setCompanyPersonAdditionalFileBack={setCompanyPersonAdditionalFileBack}
          />


          {showOwnerFields ? (
            <PayoutDetailsAdditionalPersons
              country={country}
              disabled={disabled}
              fieldId="persons"
              form={form}
              intl={intl}
              push={push}
              showEmailField={showPersonalEmailField}
              showOrganizationTitleField
              showOwnerField={showOwnerFields}
              showPersonalAddressField={showPersonalAddressField}
              showPersonalIdNumberField={showPersonalIdNumberField}
              showPhoneNumberField={showPersonalPhoneNumberField}
              values={values}
              companyPersonFileFront={companyPersonFileFront}
              companyPersonFileBack={companyPersonFileBack}
              setCompanyPersonFileFront={setCompanyPersonFileFront}
              setCompanyPersonFileBack={setCompanyPersonFileBack}
              setCompanyPersonAdditionalFileFront={setCompanyPersonAdditionalFileFront}
              setCompanyPersonAdditionalFileBack={setCompanyPersonAdditionalFileBack}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

CompanyAccountComponent.defaultProps = {
  id: null,
  disabled: false,
};

CompanyAccountComponent.propTypes = {
  fieldRenderProps: shape({
    disabled: bool,
    form: object.isRequired,
    values: object,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutDetailsCompanyAccount = compose(injectIntl)(CompanyAccountComponent);

export default PayoutDetailsCompanyAccount;
