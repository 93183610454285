import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { compose } from 'redux';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldSelect, FieldTextInput } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const optionsDoor = findOptionsForSelectFilter('garagentor', filterConfig);
      const optionsPower = findOptionsForSelectFilter('stromanschluss', filterConfig);
      const optionsGarageType = findOptionsForSelectFilter('artDerGarage', filterConfig);
  //    [
  //     {
  //         "key": "yes",
  //         "label": "Yes"
  //     },
  //     {
  //         "key": "no",
  //         "label": "No"
  //     }
  // ]
        const requiredFieldSelect = intl.formatMessage({
          id: 'EditListingFeaturesForm.requiredFieldSelect',
        });

        const requiredFieldComplete = intl.formatMessage({
          id: 'EditListingFeaturesForm.requiredFieldComplete',
        });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldSelect 
          id="bookingType" 
          name="bookingType" 
          label={<FormattedMessage id="EditListingFeaturesForm.bookTypeLabel" />}
          className={css.selectField}
          validate={composeValidators(required(requiredFieldSelect))}
          >
            <option value="" disabled hidden>{ intl.formatMessage({ id: 'EditListingFeaturesForm.bookTypePlaceholder'})}</option>
            <option value="instant">{ intl.formatMessage({ id: 'EditListingFeaturesForm.bookTypeInstant'})}</option>
            <option value="request">{ intl.formatMessage({ id: 'EditListingFeaturesForm.bookTypeRequest'})}</option>
           
          </FieldSelect>

          <FieldTextInput
            className={css.field}
            type="number"
            min={2}
            id={`minRentingMonths`}
            name="minRentingMonths"
            label={ intl.formatMessage({ id: 'EditListingFeaturesForm.minRentLabel'})}
            placeholder="3"
            validate={composeValidators(required(requiredFieldComplete))}
          />
        

          <FieldCheckboxGroup 
          label={ intl.formatMessage({ id: 'EditListingFeaturesForm.doorTypeLabel'})}
          className={css.features} 
          id="garagentor" 
          name="garagentor" 
          options={optionsDoor} 
          validate={composeValidators(required(requiredFieldSelect))}
          />

          
          <FieldCheckboxGroup 
          label={ intl.formatMessage({ id: 'EditListingFeaturesForm.powerLabel'})}
          className={css.features} 
          id="stromanschluss" 
          name="stromanschluss" 
          options={optionsPower} 
          validate={composeValidators(required(requiredFieldSelect))}
          />


          <FieldCheckboxGroup 
          label={ intl.formatMessage({ id: 'EditListingFeaturesForm.garageTypeLabel'})}
          className={css.features} 
          id="artDerGarage" 
          name="artDerGarage" 
          options={optionsGarageType} 
          multiple={true}
          validate={composeValidators(required(requiredFieldSelect))}
          />

          {/* <FieldSelect id="type" name="type" label="Garage type">
            <option value="" disabled hidden>Choose an option...</option>
            <option value="garage">Garage</option>
            <option value="duplex">Duplex</option>
            <option value="underground">Underground</option>
            <option value="outdoor">Outdoor</option>
          </FieldSelect> */}

          <FieldTextInput
            className={css.field}
            type="text"
            id={`hHeInMeter`}
            name="hHeInMeter"
            label={ intl.formatMessage({ id: 'EditListingFeaturesForm.heightLabel'})}
            placeholder="Höhe eingeben..."
            validate={composeValidators(required(requiredFieldComplete))}
          />
<br/>
          <FieldTextInput
             className={css.field}
             type="text"
             id={`breiteInMeter`}
             name="breiteInMeter"
             label={ intl.formatMessage({ id: 'EditListingFeaturesForm.widthLabel'})}
             placeholder="Breite eingeben..."
             validate={composeValidators(required(requiredFieldComplete))}
          />
<br/>
          <FieldTextInput
             className={css.field}
             type="text"
             id={`lNgeInMeter`}
             name="lNgeInMeter"
             label={ intl.formatMessage({ id: 'EditListingFeaturesForm.lengthLabel'})}
             placeholder="Länge eingeben..."
             validate={composeValidators(required(requiredFieldComplete))}
          />


          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);