import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditListingPoliciesForm.module.css';
import axios from 'axios';

const sharetribeSdk = require('sharetribe-flex-sdk');
// import { indexOf } from 'core-js/core/array';

const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

  const { UUID, LatLng } = sdkTypes;

export const EditListingPoliciesFormComponent = props =>{ 


  const [file, setFile] = useState(false);
  const [fileSend, setFileSend] = useState(false);
  const inputFileRef = React.createRef();
  const moreInfoPdf = props.currentListing.attributes.publicData.moreInfoPdf;
  const alreadySavedPdfLabel = moreInfoPdf ? moreInfoPdf.split('-')[2] : "";



return  (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentListing
      } = formRenderProps;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;


      const handleBtnClick = () => {
        /*Collecting node-element and performing click*/
        inputFileRef.current.click();
      }

     const onFileChange = event => { 
        // Update the state 
        
      const infoFile = event.target.files[0];
      setFile(infoFile)
      }; 


      const handleFileUpload = () => {

              //Create an object of formData 
            const formData = new FormData(); 
          
            // Update the formData object 
            formData.append( 
              "image", 
              file, 
              file.name 
            ); 

            const listingId = currentListing ? currentListing.id.uuid : "";
            
            return axios.post("/api/azure-upload", formData).then(resp => {
              const fileName = resp.data.fileName;

                        return sdk.ownListings.update({
                                  id: new UUID(listingId),
                                  publicData: {
                                        moreInfoPdf: fileName
                                      }
                              }).then(response => {
                                setFileSend(true)
                                return true
                              }).catch(error => console.log(error))
            }).catch(error => console.log(error))

      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            type="textarea"
            label={rulesLabelMessage}
            placeholder={rulesPlaceholderMessage}
          />

            <p className={css.pdfInfoLabel}>{intl.formatMessage({
              id: 'EditListingPoliciesForm.pdfUpLabel',
            })}</p>


            <div className={css.pdfUpload}>
                <input
                type="file"
                id="image"
                onChange={onFileChange} 
                 ref={inputFileRef} 
                className={css.hiddenUploadInput}
                />
                <div onClick={handleBtnClick} className={ moreInfoPdf ? css.dropZoneFilled : (file ? css.dropZoneFilled :  css.dropZone)}>
                  <span className={css.dropZoneLabel}>{ moreInfoPdf && !file  ? alreadySavedPdfLabel : (file ? file.name : intl.formatMessage({
              id: 'EditListingPoliciesForm.pdfUpPlaceholder',
            }))}</span>
                  </div>
                <button type="button" onClick={handleFileUpload} className={ file ? css.uploadButton : css.uploadButtonDisabled}>{fileSend ? "✓" : intl.formatMessage({
              id: 'EditListingPoliciesForm.pdfUpButton',
            })}</button>
            </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
)};

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
