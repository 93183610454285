import React , { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, Modal } from '../../components';
import moment from 'moment';
import { BookingDatesForm } from '../../forms';
import {
  SingleDatePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  isSameDay,
} from 'react-dates';
import SubscriptionBreakdown from './SubscriptionBreakdown';
import Select from 'react-select';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.module.css';
const { Money } = sdkTypes;
const commission = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;
const customerCommissionPercentage = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const sharetribeSdk = require('sharetribe-flex-sdk');


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID
  });

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const [focused, setFocused] = useState(false);
  const [firstAvailableDate, setFirstAvailableDate] = useState(new Date);
  const [stripeCustomerId, setStripeCustomerId] = useState(false);
  const [agreeModalOpen, setAgreeModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {


    sdk.listings.query().then(res => {
        const listingsArray = res.data.data
        
        return sdk.currentUser.show({
                    include: ['stripeAccount', 'stripeCustomer']
                }).then(resp => {
                    setCurrentUser(resp.data.data)
                    const hasCustomerId = resp.data.data.attributes.profile.protectedData?.stripeCustomerId;
                    if(hasCustomerId){
                      setStripeCustomerId(true)
                    }else{
                      setStripeCustomerId(false)
                    }
                    
                }).catch(e => console.log(e));
            


      }).catch(e => console.log(e));

     
  }, []);


  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onSubmitSubscribe,
    onSubmitRequestSubscribe,
    date,
    setDate,
    subscriptionPeriodLimit,
    setSubscriptionPeriodLimit,
    currentListing,
    stripeConnected,
    paypalConnected,
    openEnquiryHostNotConnected,
    onSendToPaypalSubscribe
  } = props;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const customerCommissionPrice = new Money(price.amount * (customerCommissionPercentage / 100), price.currency);
  const customerCommissionLabel = priceData(customerCommissionPrice, intl).priceTitle;
  const isBook = !!parse(location.search).book;
  const isRequestBook = listing.attributes.publicData.bookingType === 'request';
  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const blockedMonthsArray = currentListing?.attributes.publicData.blockedMonths ? currentListing.attributes.publicData.blockedMonths : [];

  const dayBlocked = (day) => {
   

    const today = new Date();
    const maximumAvailableDate = today.addDays(370);  //170
    const calendarDate = new Date(day);

    //blocked months
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"]; 
    const pickedMonth = calendarDate.getMonth();
    const pickedYear = calendarDate.getFullYear();
    const isBlockedFromMonthsArray = blockedMonthsArray.find(item => {
      return item.month === months[pickedMonth] && item.year === pickedYear;
    })

    // ---
    const firstDate = new Date(firstAvailableDate);
    const twoMonthAheadOfFirstDate = firstDate.addDays(370);  //60
    if(calendarDate.getTime() > maximumAvailableDate.getTime() || 
    calendarDate.getTime() < firstDate.getTime() ||
    calendarDate.getTime() > twoMonthAheadOfFirstDate.getTime()
    ){
      return true
    }else if(isBlockedFromMonthsArray){
      return true
    }else{
      return false
    }
  }

  const periodLimitOptions = [
    { value: 1, label: intl.formatMessage({ id: 'BookingPanel.cancelOption1' }) },
    { value: 2, label: intl.formatMessage({ id: 'BookingPanel.cancelOption2' })},
    { value: 3, label: intl.formatMessage({ id: 'BookingPanel.cancelOption3' }) },
    { value: 6, label: intl.formatMessage({ id: 'BookingPanel.cancelOption4' }) },
  ];

  const publicData = listing.attributes.publicData;
  const minRentingMonths = publicData.minRentingMonths;
  // const sendToAddPayment = () => {
  //   return history.push('/subscriptions')
  // }

  const authorPreferredPayoutMethod = currentListing?.author.attributes.profile.publicData.preferedPayoutMethod;

  const paypalPreferred = authorPreferredPayoutMethod === 'paypal';
  const stripePreferred = authorPreferredPayoutMethod === 'stripe';
 
  const subscribeToListing = () => {

      // if(stripePreferred){
        
          //stripeCustomerId ? (isRequestBook ? onSubmitRequestSubscribe : onSubmitSubscribe) : sendToAddPayment
          if(stripeCustomerId){
                if(isRequestBook){
                    return onSubmitRequestSubscribe()
                }else{
                    return onSubmitSubscribe()
                }

          }else{
            if(typeof window !== 'undefined'){
              sessionStorage.setItem('returnUrl', window.location.pathname);
            }
            return history.push('/subscriptions')
          }

      // }else if(paypalPreferred){
      //         if(isRequestBook){
      //           // return onSubmitRequestSubscribePaypal()
      //         }else{
      //             return onSendToPaypalSubscribe()
      //         }
      // }
  }
  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
        </div>
        {/* {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : null} */}
           <div  className={css.datePicker}>
                  <p className={css.selectDateLabel}>{intl.formatMessage({ id: 'BookingPanel.question1' })}</p>
                            <SingleDatePicker
                                  readOnly
                                  focused={focused}
                                  onFocusChange={(values) => setFocused(values.focused)}
                                  date={date}
                                  onDateChange={(date) => setDate(date)}
                                  numberOfMonths={1}
                                  displayFormat='📅 DD MM YYYY'
                                  phrases={{ closeDatePicker: "close", clearDate: "clear" }}  
                                  isDayBlocked={dayBlocked} 
                                  placeholder={intl.formatMessage({ id: 'BookingPanel.selectDatePlaceholder' })}    
                                />
                        </div>
             <div  className={css.periodLimitPicker}> 
             <p className={css.selectDateLabel}>{intl.formatMessage({ id: 'BookingPanel.question2' })}</p>

                        <Select
                        value={subscriptionPeriodLimit}
                        onChange={setSubscriptionPeriodLimit}
                        options={periodLimitOptions}
                        placeholder={intl.formatMessage({ id: 'BookingPanel.cancelPeriodPlaceholder' })}
                        className={css.endSubsSelect}
                      /> 
              </div>
          {minRentingMonths ? 
          <p></p>
          : null}


            <SubscriptionBreakdown
            hideBreakdown={date === null}
            currentListing={currentListing}
            priceTitle={priceTitle}
            customerCommissionLabel={customerCommissionLabel}
            date={date}
            />


        <div  className={css.subcribeButton}>
          <Button 
          onClick={() => {
            if(!currentUser){
              if(typeof window !== 'undefined'){
                sessionStorage.setItem('returnUrl', window.location.href);
              }
              history.push('/signup')
            }

            // if(!stripeConnected && !paypalConnected){
            if(!stripeConnected){

             return openEnquiryHostNotConnected()
            }else{
              setAgreeModalOpen(true)}}
            }

          disabled={!date}
          >
          {isRequestBook ? intl.formatMessage({ id: 'BookingPanel.requestToSubscribe' }) : intl.formatMessage({ id: 'BookingPanel.bookNow' })}
        
          </Button>


          <Modal
            isOpen={agreeModalOpen}
            onClose={() => {
              setAgreeModalOpen(false);
            }}
            onManageDisableScrolling={() => {}}
          >
              <center>
              <h2>{intl.formatMessage({ id: 'BookingPanel.subsModalTitle' })}</h2>
              </center>

              <p className={css.infoText}>{intl.formatMessage({ id: 'BookingPanel.subsModalInfo1' }, { commission })}</p>
              <p className={css.infoText}><a href='/terms-of-service'>{intl.formatMessage({ id: 'BookingPanel.subsModalInfo2' })}</a></p>
              <p></p>


             <Button 
              onClick={subscribeToListing}
              disabled={!date || props.loading}
              >
            {props.loading ? intl.formatMessage({ id: 'BookingPanel.modalLoading' }) : isRequestBook ? intl.formatMessage({ id: 'BookingPanel.modalReqSubmit' }) : intl.formatMessage({ id: 'BookingPanel.modalInstantSubmit' })}
          
          </Button>
        </Modal>
        </div>
        {minRentingMonths ? 
          <p className={css.selectDateLabel}><FormattedMessage id={"BookingPanel.beAware"} values={{minRentingMonths}}/></p>
          : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingDatesForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
